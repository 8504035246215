
import Vue, { PropType } from 'vue'
import NavigationTab from '~/components/_general/NavigationTab.vue'
import HorizontalScroll from '~/components/_general/HorizontalScroll.vue'
import { NavigationTabItem, NavigationTabs } from '~/assets/ts/enums'
import SaLinkOrSpan from '~/components/_general/SaLinkOrSpan.vue'
import { modifyQs } from '~/assets/ts/utils/params'

export default Vue.extend({
  name: 'NavigationTabList',
  components: { SaLinkOrSpan, HorizontalScroll, NavigationTab },
  model: {
    prop: 'active',
    event: 'click',
  },
  props: {
    tabs: {
      type: Array as PropType<NavigationTabs>,
      required: true,
    },
    /** Used to remove the qs if it's the default tab */
    defaultTab: {
      type: String,
      default: '',
    },
    active: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    /**
     * If not provided, the clicked tabs will simply emit the selected slug.
     *
     * If provided, tabs will become links to a new page.
     * */
    baseUrl: {
      type: String,
      default: undefined,
      validator(value: string | undefined) {
        return !value || (value.startsWith('/') && value.endsWith('/'))
      },
    },
    /**
     * If provided, the tab will update the page query string with the provided key
     *
     * */
    qsKey: {
      type: String,
      default: undefined,
    },
    inactiveColor: {
      type: String,
      default: undefined,
    },
    activeColor: {
      type: String,
      default: undefined,
    },
    border: {
      type: Boolean,
      default: true,
    },
    /** This is the tailwind gap size */
    gapSize: {
      type: String,
      default: 'gap-0',
      validator(value: string) {
        return value.includes('gap-')
      },
    },
    tabClasses: {
      type: String,
      default: '',
    },
  },
  methods: {
    url(tab: NavigationTabItem) {
      if (tab.url) return tab.url
      if (this.baseUrl) return `${this.baseUrl}${tab.slug}`
      return undefined
    },
    click(tab: string) {
      if (this.active === tab) return
      this.$emit('click', tab)
      if (!this.qsKey) return
      const qs = {} as Record<string, string>
      qs[this.qsKey] = tab === this.defaultTab ? '' : tab
      modifyQs(this, qs)
    },
  },
})
